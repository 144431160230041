@import url(https://fonts.googleapis.com/css2?family=Asap:ital,wght@0,400;0,700;1,400;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Anton&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}




:root {
    --priCol: #2b2b2b;
    --priColLight: #fd5239;
    --accCol: #ff7424;
    --fontcolor: #101010;
    --fontcolorLight1: gray;
    --fontfamily: 'Asap';
    --basicFontSize: 1.2rem;
    --offWhite: #f1f1f1a9;
    --errorColor: #ea0505;
    --themeColor: #007bff;
    --unlayerBackground: #F9F9F9;
    --unlayerBorders: #ececec;
    --ss: #fd5239
}

/*#region Default App and Header Tab */

.notFount404 {
    font-family: 'Anton', sans-serif;
}
.rightInfoIcon{
    float:right;margin-top:0px;margin-right:5px;
    cursor:pointer
}
.App {
    color: #101010;
    color: var(--fontcolor);
    font-family: 'Asap';
    font-family: var(--fontfamily);
    background-color: white;
}

/*#endregion */

/*#region Navigation & Pagination */

.rocNav {
    color: white;
    padding: 10px;
    text-align: center
}

.rocNav:hover {
    text-decoration: underline;
    color: white
}

.rocNav:focus {
    text-decoration: underline;
    color: white
}

.rocNav.disabled {
    color: #6c757d
}

.pagination {
    margin: 20px;
    display: -webkit-flex;
    display: flex;
    list-style: none;
    outline: none;
    -webkit-justify-content: center;
            justify-content: center;
    color: gray;
    color: var(--fontcolorLight1);
}

.eachPagePrevNex {
    padding: 5px 15px;
    font-size: 1.1em;
    margin: 5px;
    color: var(--fontcolorLight);
}

.eachPage {
    padding: 5px 15px;
    font-size: 1.1em;
    font-weight: bold;
    margin: 5px
}

.activePage {
    background-color: #007bff;
    background-color: var(--themeColor);
    color: white;
    border-radius: 5px;
}

.break-me {}

/*#endregion */

/*#region Distinct*/

/*#region Alpha */

.lowalpha {
    opacity: 0.8
}

.xlowalpha {
    opacity: 0.6
}

.xxlowalpha {
    opacity: 0.4
}
.xxxlowalpha {
    opacity: 0.2
}
.hidden {
    display: none;
}

/*#endregion */

/*#region Fonts */

.link {
    color: #007bff;
    color: var(--themeColor);
    text-decoration: none;
    cursor: pointer;
}

.link:hover {
    text-decoration: underline;
}

.titles {
    font-size: calc(1.2rem*3);
    font-size: calc(var(--basicFontSize)*3);
}

.stitle {
    font-size: calc(1.2rem*2);
    font-size: calc(var(--basicFontSize)*2);
}

.sstitle {
    font-size: calc(1.2rem*1.5);
    font-size: calc(var(--basicFontSize)*1.5);
}

.ssstitle {
    font-size: calc(1.2rem*1.1);
    font-size: calc(var(--basicFontSize)*1.1);
}

.bold {
    font-weight: bold
}

.normal {
    font-weight: normal
}

.underline {
    text-decoration: underline;
}

.whiteColor {
    color: white
}

.primColor {
    color: #2b2b2b;
    color: var(--priCol)
}

.themeColor {
    color: #007bff;
    color: var(--themeColor)
}

.hint {
    color: gray;
    color: var(--fontcolorLight1)
}

.warningColor {
    color: #ea0505;
    color: var(--errorColor)
}

.warningYellow{
    color:#ebc905
}
.notValidColor {
    color: #ea0505
}

.successColor {
    color: #1fae0b
}

.remarks {
    font-size: calc(1.2rem*0.8);
    font-size: calc(var(--basicFontSize)*0.8);
}
.midremarks {
    font-size: calc(1.2rem*0.7);
    font-size: calc(var(--basicFontSize)*0.7);
}
.sremarks {
    font-size: calc(1.2rem*0.5);
    font-size: calc(var(--basicFontSize)*0.5);
}

/*#endregion */

/*#region Layout*/

/*#region Flex*/

.flexrow {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}

.flexrow.center {
    -webkit-justify-content: center;
            justify-content: center
}

.flexrow.spacearround {
    -webkit-justify-content: space-around;
            justify-content: space-around
}

.flexrow.spacebetween {
    -webkit-justify-content: space-between;
            justify-content: space-between
}

.flexrow.end {
    -webkit-justify-content: flex-end;
            justify-content: flex-end
}

.flexrow.wrap {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap
}

.flexrow.verticalcenter {
    -webkit-align-items: center;
            align-items: center;
}

.flexcolumn {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.flexcolumn.spacearround {
    -webkit-justify-content: space-around;
            justify-content: space-around
}

.flexcolumn.spacebetween {
    -webkit-justify-content: space-between;
            justify-content: space-between
}

.flexcolumn.center {
    -webkit-justify-content: center;
            justify-content: center
}

.flexcolumn.verticalcenter {
    -webkit-align-items: center;
            align-items: center;
}

.flex {
    display: -webkit-flex;
    display: flex;
}

.flex.center {
    -webkit-justify-content: center;
            justify-content: center;
}

.flex.verticalcenter {
    -webkit-align-items: center;
            align-items: center;
}

/*#endregion*/

/* #region Card */

.Card {
    border-radius: 5px;
    border-color: whitesmoke;
}

.Card.bigRound {
    border-radius: 40px
}

.mainScreenCard {
    border-radius: 5px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 2px 2px 0 rgba(0, 0, 0, 0.1);
    padding: 15px 5px;
    border-width: 0.1px;
      cursor: none;
      border-top-style: solid;
      border-width: 0.3px;
    max-width: 20%;
    color: #ececec;
    color: var(--unlayerBorders)
}

.mainScreenCard:hover {
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 4px 0 rgba(0, 0, 0, 0.2);
    border-width: 0.2px;
    border-color: #ececec;
    border-color: var(--unlayerBorders);
    color: #007bff;
    color: var(--themeColor);
    cursor: pointer;
     
     
}

.mainCampaignStatitics{
  border-radius: 15px;
  border-color: #ececec;
  border-color: var(--unlayerBorders);
  padding: 5px;
  border-style: solid;
  border-width: 1px;
 
    
}
.mainCampaignheader{
    /* font-family: 'Anton', sans-serif; */
    letter-spacing: 0.1em;
    font-size: 0.5;
    font-weight: bold;
    
     
}

/* #endregion */

/*#region Image*/

.circleImage {
    background-color: white;
    border-style: solid;
    border-width: 3px;
    border-color: #2b2b2b;
    border-color: var(--priCol);
    border-radius: 100%;
    object-fit: contain;
    width: 64px;
    height: 64px;
    padding: 10px;
}

/*#endregion*/

/*#region Width & Height & Float*/

.fullWidth {
    width: 100%;
}

.fullHeight {
    min-height: 110vh;
}

.miniContentHeight {
    min-height: 85vh;
}
.maxHeight30 {
    min-height: 30;
}
.floRig {
    float: right;
}

.select:hover{
    background-color: rgb(252, 252, 255, 0.3);
    color: #007bff;
    color: var(--themeColor);
}
.outlineBorderButton{
    border-radius: 5px;
    border-color: #007bff;
    border-color: var(--themeColor);
    border-style: solid;
    border-width: 1px;
    color:#007bff;
    color:var(--themeColor)
}
.outlineBorderButton:hover{
    border-radius: 5px;
    border-color: white;
    border-style: solid;
    border-width: 1px;
    background-color: #007bff;
    background-color: var(--themeColor);
    color:white
}
/*#endregion*/

/*#region Box And Border*/

.boxShadow {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.sboxShadow {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 2px 2px 0 rgba(0, 0, 0, 0.1);
}

.boxRadius5 {
    border-radius: 5px;
}

.boxTop15Bottom25 {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 45px;
    border-Top-left-radius: 45px;
    border-Top-right-radius: 5px;
}

.boxBOttomRadius10 {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.boxTopRadius5 {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.pageDivision {
    background-color: #007bff;
    background-color: var(--themeColor);
    color: white;
    padding: 0.5em;
    border-radius: 5px;
}

.warningBorder {
    border-color: #ea0505;
    border-color: var(--errorColor)
}

.boxLightGray {
    border-color: lightgray;
    border-style: solid;
    border-width: 0.3px;
    border-radius: 3px;
    padding-top: 5px;
    padding-bottom: 6px;
    padding-left: 10px;
}

/*#endregion*/

/*#region BackgroundColor*/

.whiteBg {
    background-color: white
}

.themeBg {
    background-color: #007bff;
    background-color: var(--themeColor);
}

/*#endregion*/

/* #region dropdown */

.no-suggestions {
    color: #ea0505;
    color: var(--errorColor);
}

.suggestions {
    display: block;
    position: absolute;
    background-color: white;
    border-style: solid;
    border-radius: 5px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
    border-width: 0.2px;
    padding-left: 0px;
    border-color: lightgray;
    min-width: 500px;
    max-height: 300px;
    overflow-y: auto;
    z-index: 1;
}

.suggestions li {
    padding: 0.5rem;
    background-color: white;
}

.suggestion-noneactive {
    background-color: white;
    color: #2b2b2b;
    color: var(--priCol);
    cursor: pointer;
    padding: 0.5rem;
}

.suggestion-noneactive:hover {
    background-color: #007bff;
    background-color: var(--themeColor);
    color: white;
    cursor: pointer;
}

.suggestion-active {
    background-color: #007bff;
    background-color: var(--themeColor);
    color: white;
    cursor: pointer;
    padding: 00.5em;
}

.suggestions li:not(:last-of-type) {
    border-bottom: 1px solid white;
}

/* #endregion */

/* #region Buttons */

.successButton {
    background-color: green;
    padding: 10px;
    border-radius: 5px;
    color: white
}

/*#endregion*/

/*#endregion*/

/*#region Mouse*/

.handCursor {
    cursor: pointer;
}

/*#endregion*/

/*#region unlayer*/

.unlayerStatusBackground {
    background-color: #F9F9F9;
    background-color: var(--unlayerBackground);
    border-right-color: #ececec;
    border-right-color: var(--unlayerBorders);
    border-right-style: solid;
    border-right-width: 1px;
    padding-right: 5px;
    min-width: 15%;
}

.unlayerSVG {
    -webkit-filter: invert(14%) sepia(0%) saturate(2136%) hue-rotate(190deg) brightness(93%) contrast(77%);
            filter: invert(14%) sepia(0%) saturate(2136%) hue-rotate(190deg) brightness(93%) contrast(77%);
}

.unlayerButton {
    background-color: white;
    color: #2b2b2b;
    color: var(--priCol);
    cursor: pointer;
    border-radius: 3px;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 10px;
    border-color: #ececec;
    border-color: var(--unlayerBorders);
    border-width: 1px;
    border-style: solid;
    padding: 15px;
    text-transform: capitalize;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    font-size: 0.8rem;
    text-align: center;
}

.unlayerButton:hover {
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
}

.editCopyHover {
    border-bottom-style: solid;
    border-color: #007bff;
    border-color: var(--themeColor);
    border-radius: 3px;
    border-width: 0.1px;
}

.editCopyHover:hover {
    border-color: white;
}

.unlayerHotspotbutton {
    cursor: unset;
}

.unlayerHotspot {
    background-color: #007bff;
    background-color: var(--themeColor);
    border-radius: 5px;
    color: white;
    padding-top: 10px;
    padding-bottom: 10px;
}

.unlayerHeader {
    background-color: #007bff;
    background-color: var(--themeColor);
    padding: 5px;
    border-width: 2px;
    color: white;
    border-color: #ececec;
    border-color: var(--unlayerBorders);
    margin-bottom: 10px;
    margin-top: 20px;
    border-top-right-radius: 5px;
}

/*#endregion*/

/*#region Toast*/

.toastBackground {
    position: absolute;
    z-index: 100;
    bottom: 200px;
}

.toastMessage {
    background-color: #2b2b2b;
    background-color: var(--priCol);
    color: white;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    border-radius: 8px;
    padding: 15px
}

/*#endregion*/

/*#region Toast*/

.filterProfileLabel {
    margin-right: 10px;
    padding: 2px 15px;
}

.filterProfileInput {
    padding: 2px 15px;
    border-radius: 5px;
    background-color: #f1f1f1a9;
    background-color: var(--offWhite);
    margin-right: 10px;
    -webkit-columns: 2;
            columns: 2;
    cursor: pointer;
}

/*#endregion*/

.wrapper {
    position: relative;
    background-color: rgba(0, 0, 0, 0);
    width: 100%;
    height: 100%;
    font-size: 10px;
}

.content {
    position: absolute;
    top: 50%;
    left: 50%;
}

.slideshow-wrapper {
    position: relative;
    float: left;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.title {
    position: absolute;
    color: #fff;
    font-size: 3em;
    text-transform: uppercase;
    letter-spacing: 3px;
    word-spacing: 6px;
    bottom: 15px;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 2;
    font-family: 'Anton', sans-serif;
}
.titleCopy {
     font-size: 2em;
    text-transform: uppercase;
    letter-spacing: 3px;
    word-spacing: 6px;
    font-family: 'Anton', sans-serif;
}


.slideshow-wrapper:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* background: rgba(24, 24, 26, 0.6); */
    background:white;
    z-index: 1;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.withoutborder {
    border-style: none;
}

/*#endregion*/

/*#region Distinct*/

/*#endregion*/

/*#region Media Screens*/

/*Min-width: 320px (smaller phone viewpoints)
Min-width: 480px (small devices and most phones)
Min-width: 768px (most tablets)
Min-width: 992px (smaller desktop viewpoints)
Min-width: 1200px (large devices and wide screens)
*/

.showRightHeaderText{
    visibility: collapse;
    
}




@media screen and (min-width: 0px) {
    .screensize {
        background-color: red;
    }
    
}

@media screen and (min-width: 320px) {
    .screensize {
        background-color: green;

    }
   
}

@media screen and (min-width: 480px) {
    .screensize {
        background-color: blue;
    }
    .showRightHeaderText{
        visibility: visible;
    }
}

@media screen and (min-width: 768px) {
    .screensize {
        background-color: orange;
    }
   
}

@media screen and (min-width: 992px) {
    .screensize {
        background-color: yellow;
    }
}

@media screen and (min-width: 1100px) {
    .screensize {
        background-color: rgb(189, 103, 156);
    } 
}

@media screen and (max-width: 1100px) {
     
}

@media screen and (min-width: 1200px) {
    .screensize {
        background-color: mediumpurple;
    }
}

.redSVG {
    -webkit-filter: invert(10%) sepia(94%) saturate(7152%) hue-rotate(10deg) brightness(94%) contrast(110%);
            filter: invert(10%) sepia(94%) saturate(7152%) hue-rotate(10deg) brightness(94%) contrast(110%);
}

.whitesvg {
    -webkit-filter: invert(100%) sepia(0%) saturate(4515%) hue-rotate(83deg) brightness(123%) contrast(110%);
            filter: invert(100%) sepia(0%) saturate(4515%) hue-rotate(83deg) brightness(123%) contrast(110%);
}
.graysvg {
    -webkit-filter: invert(88%) sepia(12%) saturate(0%) hue-rotate(232deg) brightness(90%) contrast(105%);
            filter: invert(88%) sepia(12%) saturate(0%) hue-rotate(232deg) brightness(90%) contrast(105%);
}
.letUsStartSVG{
    -webkit-filter: invert(100%) sepia(3%) saturate(187%) hue-rotate(199deg) brightness(112%) contrast(88%);
            filter: invert(100%) sepia(3%) saturate(187%) hue-rotate(199deg) brightness(112%) contrast(88%);
    
}

/*#endregion*/

/*#region Debug */

.ctheme {
    background-color: #007bff;
    background-color: var(--themeColor)
}

.cprim {
    background-color: #2b2b2b;
    background-color: var(--priCol)
}

.cprimlight {
    background-color: #fd5239;
    background-color: var(--priColLight)
}

.cr {
    background-color: red
}

.cb {
    background-color: blue
}

.cbe {
    background-color: beige
}

.colSupLigGra {
    background-color: #f3f3f3
}

.cwhite {
    background-color: white;
}

.cunlayer {
    background-color: #F9F9F9;
    background-color: var(--unlayerBackground);
}

.cSignInBack {
    background-color: #ebeeef;
}

/*#endregion */

/* #region Animation */


@-webkit-keyframes example {
    0%   {opacity: 0}
    80% {opacity: 0;}
    100%  {opacity: 1;}
    
  }


@keyframes example {
    0%   {opacity: 0}
    80% {opacity: 0;}
    100%  {opacity: 1;}
    
  }

  .animateNotFoundAppear{
    -webkit-animation-name: example;
            animation-name: example;
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
  }
/* #endregion */


select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
}   
